import React from 'react'
import PropTypes from 'helpers/proptypes'
import { css } from 'styled-components'
import { useLocale } from 'hooks/locales/useLocale'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { NextImage } from 'components/shared/NextImage'
import { Section } from '@vizeat/components/es6/components/Section'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { RichText } from 'prismic-reactjs'
import { ChevronIconButton } from 'components/shared/buttons'

const imageStyle = css`
  object-fit: cover;
  border-top-right-radius: ${({ theme }) => theme.radii.lg};
  border-bottom-right-radius: ${({ theme }) => theme.radii.lg};
`

function HighlightCard({ alt, children, imageSource }) {
  return (
    <Grid
      templateColumns={{ desktop: '60% 40%', default: '1fr' }}
      gridAutoRows='auto'
      bg='turquoise'
      color='white'
      height='100%'
      borderRadius='lg'
    >
      <Box height='100%' py='32px' px={{ desktop: '56px', default: '16px' }}>
        {children}
      </Box>
      <Box position='relative' display={{ desktop: 'block', default: 'none' }}>
        <NextImage src={imageSource} alt={alt} fill imageStyle={imageStyle} />
      </Box>
    </Grid>
  )
}

HighlightCard.propTypes = {
  children: PropTypes.node.isRequired,
  imageSource: PropTypes.number.isRequired,
  alt: PropTypes.string,
}

HighlightCard.defaultProps = {
  alt: undefined,
}

export function HighlightsSection({
  staticFields: {
    anchor_id: anchorId,
    title,
    description,
    button_content: buttonText,
    button_link: href,
    image,
    alt,
    subtitle,
  },
  customButton,
}) {
  const { locale } = useLocale()

  return (
    <Section id={anchorId} bg='white' textAlign='left'>
      <HighlightCard alt={alt} imageSource={image}>
        <Heading as='h2' locale={locale} color='white' customEndingMarks fontSize='30px' m='0' fontWeight='bolder'>
          {title}
        </Heading>
        {subtitle && (
          <Heading as='h3' type='medium' color='white' mt='16px' mb='0' fontWeight='medium' fontSize='24px'>
            {subtitle}
          </Heading>
        )}
        {description && (
          <Box mt={{ default: '8px', tablet: '36px' }}>
            <RichText render={description} />
          </Box>
        )}
        {customButton}
        {!customButton && buttonText && (
          <Flex mt={{ default: '40px', tablet: '32px' }} justifyContent={{ default: 'center', desktop: 'flex-start' }}>
            <ChevronIconButton href={href} color='turquoise'>
              {buttonText}
            </ChevronIconButton>
          </Flex>
        )}
      </HighlightCard>
    </Section>
  )
}

HighlightsSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.shape({})),
    button_content: PropTypes.string,
    button_link: PropTypes.string,
    image: PropTypes.number,
    alt: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  customButton: PropTypes.node,
}

HighlightsSection.defaultProps = {
  customButton: null,
}
