import { useIsRtl } from 'hooks/locales/useIsRtl'

export function useRichTextContent(text) {
  const { direction } = useIsRtl()

  return [
    {
      type: 'paragraph',
      text,
      spans: [],
      direction,
    },
  ]
}
